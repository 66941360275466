import { useState, useEffect } from 'react'
import { Code, Server, Globe, Mail, Github, Linkedin,  Menu, X, ChevronDown, FilePlus2 } from 'lucide-react'
import Projects from '../components/projects'
import Resume from '../img/AdrianRojo-Resume.pdf';

export default function Component() {
  const [activeSection, setActiveSection] = useState('')
  const [isMenuOpen, setIsMenuOpen] = useState(false)


  useEffect(() => {
    const handleScroll = () => {
      const sections = ['home', 'skills', 'projects', 'contact']
      const scrollPosition = window.scrollY

      const currentSection = sections.find(section => {
        const element = document.getElementById(section)
        if (element) {
          const { offsetTop, offsetHeight } = element
          return scrollPosition >= offsetTop && scrollPosition < offsetTop + offsetHeight
        }
        return false
      })

      if (currentSection) {
        setActiveSection(currentSection)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const closeMenu = () => {
    setIsMenuOpen(false)
  }

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100">
      {/* Header */}
      <header className="fixed top-0 left-0 right-0 bg-gray-900 bg-opacity-90 z-40">
        <nav className="container mx-auto px-6 py-4 flex justify-between items-center">
          <a href="#home" className="text-2xl font-bold">{`<A/R>`}</a>
          <div className="hidden md:block">
            <NavLinks activeSection={activeSection} />
          </div>
          <button onClick={toggleMenu} className="md:hidden text-gray-100 focus:outline-none">
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </nav>
      </header>

      {/* Mobile Menu */}
      <div className={`fixed inset-y-0 right-0 w-64 bg-gray-800 transform ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out z-20 md:hidden`}>
        <div className="p-6">
          <button onClick={closeMenu} className="absolute top-4 right-4 text-gray-100 focus:outline-none">
            <X size={24} />
          </button>
          <nav className="mt-8">
            <NavLinks activeSection={activeSection} onClick={closeMenu} />
          </nav>
        </div>
      </div>

      {/* Hero Section */}
      <section id="home" className="bg-gray-800 min-h-screen flex items-center justify-center relative overflow-hidden">
        <div className="absolute inset-0 z-0">
          <div className="absolute inset-0"></div>
          <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjUiPgo8cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSI1IiBmaWxsPSIjMjEyMTIxIj48L3JlY3Q+CjxwYXRoIGQ9Ik0wIDVMNSAwWk02IDRMNCA2Wk0tMSAxTDEgLTFaIiBzdHJva2U9IiMzYTNhM2EiIHN0cm9rZS13aWR0aD0iMSI+PC9wYXRoPgo8L3N2Zz4=')] opacity-20"></div>
        </div>
        <div className="text-center z-10 px-4 sm:px-6 lg:px-8">
          <h1 className="text-5xl sm:text-6xl lg:text-7xl font-bold mb-4 animate-fade-in-up">
            Adrian Rojo
          </h1>
          <p className="text-xl sm:text-2xl text-blue-300 mb-8 animate-fade-in-up animation-delay-200">
            DevOps Engineer & Web Developer
          </p>
          <div className="flex flex-col sm:flex-row justify-center items-center gap-4 animate-fade-in-up animation-delay-400">
            <a
              href={Resume}
              download="AdrianRojo-Resume.pdf"
              label="Resume"
              className="bg-blue-500 text-white px-8 py-3 rounded-full font-semibold hover:bg-blue-600 transition-colors transform hover:scale-105 duration-300 ease-in-out"
            >
              Resume
            </a>
            <a
              href="#projects"
              className="text-blue-300 border border-blue-300 px-8 py-3 rounded-full font-semibold hover:bg-blue-300 hover:text-gray-900 transition-colors transform hover:scale-105 duration-300 ease-in-out"
            >
              View Projects
            </a>
          </div>
        </div>
        <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 animate-bounce">
          <a href="#skills" aria-label="Scroll to skills section">
            <ChevronDown size={32} className="text-blue-300" />
          </a>
        </div>
      </section>
      {/* Skills Section */}
      <section id="skills" className="py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">My Skills</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <SkillCard icon={<Code size={40} />} title="Web Development" skills={['React', 'Node.js', 'PHP', 'Tailwind CSS', 'MySQL']} />
            <SkillCard icon={<Server size={40} />} title="DevOps" skills={['Docker', 'Kubernetes', 'AWS', 'CI/CD', 'Linux', 'AWS']} />
            <SkillCard icon={<Globe size={40} />} title="Other" skills={['Git', 'Agile', 'RESTful APIs', 'Google Analytics', 'Adobe Illustrator']} />
          </div>
        </div>
      </section>

      {/* Projects Section */}
      <section id="projects" className="py-20 bg-gray-800 z-0 relative">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-center mb-12">Recent Projects</h2>
          <Projects />
        </div>
      </section>

      {/* Contact Section */}
      <section id="contact" className="py-20">
        <div className="container mx-auto px-6 text-center">
          <h2 className="text-3xl font-bold mb-8">Get in Touch</h2>
          <p className="text-xl text-gray-400 mb-8">I'm always open to new opportunities and collaborations.</p>
          <div className="flex justify-center space-x-6">
            <SocialLink href="mailto:aricardorojo@gmail.com" icon={<Mail size={24} />} label="Email" />
            <SocialLink href="https://github.com/AdrianRRojo" target='_blank' icon={<Github size={24} />} label="GitHub" />
            <SocialLink href="https://www.linkedin.com/in/adrianrrojo/" target='_blank' icon={<Linkedin size={24} />} label="LinkedIn" />
            <SocialLink href={Resume} download="AdrianRojo-Resume.pdf" icon={<FilePlus2 size={24} />} label="Resume" />
          </div>
        </div>
      </section>
    </div>
  )
}

function NavLinks({ activeSection, onClick }) {
  const links = ['Home', 'Skills', 'Projects', 'Contact']
  return (
    <ul className="space-y-4 md:space-y-0 md:flex md:space-x-8">
      {links.map((item) => (
        <li key={item}>
          <a
            href={`#${item.toLowerCase()}`}
            onClick={onClick}
            className={`text-sm uppercase tracking-wider hover:text-blue-400 transition-colors ${
              activeSection === item.toLowerCase() ? 'text-blue-400' : ''
            }`}
          >
            {item}
          </a>
        </li>
      ))}
    </ul>
  )
}

function SkillCard({ icon, title, skills }) {
  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 text-center">
      <div className="text-blue-500 mb-4 flex justify-center">{icon}</div>
      <h3 className="text-xl font-bold mb-4">{title}</h3>
      <ul className="space-y-2">
        {skills.map((skill, index) => (
          <li key={index}>{skill}</li>
        ))}
      </ul>
    </div>
  )
}



function SocialLink({ href, icon, label }) {
  return (
    <a
      href={href}
      className="text-gray-400 hover:text-blue-400 transition-colors"
      aria-label={label}
      target="_blank"
      rel="noreferrer"
    >
      {icon}
    </a>
  )
}